import type { ReactNode } from "react";
import clsx from "clsx";
import _ from "lodash";
import React from "react";

type Props = {
  itemClass?: string;
  columns: number;
  children: ReactNode;
};

/**
 * This exists because adding internal borders on CSS Grid is a pain in the ass.
 */
export default function Grid({ children, itemClass = "", columns }: Props) {
  return (
    <table className="w-full border-collapse">
      <tbody className="divide-y divide-gray-200">
        {_.chunk(React.Children.toArray(children), columns).map((chunk, i) => (
          <tr key={i} className="divide-x divide-gray-200">
            {chunk.map((c, i) => (
              <td
                key={i}
                className={clsx("!px-2 !py-6 text-center", itemClass)}
                style={{ width: `${100 / columns}%` }}
              >
                {c}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
